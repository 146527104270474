<template>
  <span class="badge bg-primary col-sm-2 col-md-2 py-1"
    >Total registros: {{ totalRegister }}</span
  >
</template>

<script>
export default {
  name: 'totalRegister',
  data() {
    return {};
  },
  props: ['totalRegister'],
};
</script>
