<template>
  <b-modal id="bv-modal-example" v-model="modal" size="lg" hide-footer>
    <template #modal-title> Detalles </template>
    <div class="row mt-4">
      <!-- <div v-if="!details" class="mb-4">
          No hay Jackpots para esta ronda
        </div> -->
      <div class="row">
        <div class="col-6">
          <h3>Valor anterior</h3>
          <div v-for="(detail, index) in oldValue" :key="index">
            <span v-if="detail[0] !== '_id'" class="mb-2"
              ><strong>{{ detail[0] }}: <br /></strong>
              <span>{{ detail[1] }}</span>
            </span>
          </div>
        </div>

        <div class="col-6">
          <h3>Campos afectados</h3>
          <div v-for="(detail, index) in newValue" :key="index">
            <span v-if="detail[0] !== '_id'" class="mb-2"
              ><strong>{{ detail[0] }}: <br /></strong>
              <span>{{ detail[1] }}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: ['oldValue', 'newValue'],
  data() {
    return {
      modal: false,
      fields: [
        {
          key: 'betType',
          label: this.$t('betDetail.type'),
          class: 'text-center',
        },
        {
          key: 'number',
          label: this.$t('betDetail.number'),
        },
        {
          key: 'amount',
          label: this.$t('betDetail.amount'),
          class: 'text-center',
        },
        {
          key: 'earnings',
          label: this.$t('betDetail.win'),
          class: 'text-center',
        },
      ],
      betFields: [
        {
          key: 'betType',
          label: this.$t('betDetail.type'),
        },
        {
          key: 'number',
          label: this.$t('betDetail.number'),
        },
        {
          key: 'amount',
          label: this.$t('betDetail.amount'),
          class: 'text-center',
        },
      ],
      dummie: [{ number: 1, amount: 100, type: 'pleno' }],
      reds: [
        1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 25, 27, 30, 32, 34, 36,
      ],
    };
  },
  methods: {
    closeModa() {
      this.modal = false;
      this.items = [];
    },
    handleOver(e) {
      const element = e.target;
      const badge = element.querySelector('.badge-time');
      if (badge) {
        badge.style.visibility = 'visible';
      }
    },
    handleLeave(e) {
      const element = e.target;
      const badge = element.querySelector('.badge-time');
      if (badge) {
        badge.style.visibility = 'hidden';
      }
    },
  },
};
</script>

<style scoped>
.black {
  background: #000;
  color: #fff;
  padding: 4px;
  text-align: center;
}

.red {
  background: #f10;
  color: #fff;
  padding: 4px;
  text-align: center;
}

.green {
  background: #00a429;
  color: #fff;
  padding: 4px;
  text-align: center;
}
</style>
